<template>
  <div id="merchant-list">
    <kr-card :padding="[0, 0, 12, 0]" @rect:update="onTopCardRectUpdate">
      <shop-list-search @success="searchHandler" :loading="listLoading" />
    </kr-card>
    <a-table
      :scroll="{ x: 800, y: tableScrollY }"
      :height="500"
      v-auth="'business_list_page'"
      :columns="ShopTableList"
      :dataSource="list"
      :loading="listLoading"
      :locale="{ emptyText: '暂无信息' }"
      :pagination="tablePage"
      @change="paginationHandler"
      row-key="user_id"
    >
      <template slot="auth" slot-scope="row">
        <span :class="row.audit_status | certificationClass">{{
          row.audit_status | shopMargin(Certification)
        }}</span>
      </template>
      <template slot="money" slot-scope="row">
        <span v-if="row.audit_status === 3"
          >{{ row.pay_deposit_status | shopMargin(Margin)
          }}{{
            row.pay_deposit_status === 2 ? `(${thousandsFormat(row.deposit_money)}元)` : ''
          }}</span
        >
        <span v-else>未缴纳</span>
      </template>
      <template slot="contact_tel" slot-scope="row">{{ row.contact_tel || '--' }}</template>
      <template slot="contact__username" slot-scope="row">{{
        row.contact__username || '--'
      }}</template>
      <template slot="type" slot-scope="row">{{
        row.audit_status === 3 ? shopMargin(row.brand_type, Brand) : '--'
      }}</template>
      <template slot="name" slot-scope="row">{{ row.shop_name || '--' }}</template>
      <template slot="user_create_time" slot-scope="row">{{
        row.user_create_time | timeFormat('YYYY-MM-DD HH:mm')
      }}</template>
      <template slot="last_login_time" slot-scope="row">{{
        row.last_login_time | timeFormat('YYYY-MM-DD HH:mm')
      }}</template>
      <template slot="action" slot-scope="row">
        <a-button size="small" @click="showDetail(row)">查看</a-button>
      </template>
    </a-table>
    <a-drawer
      title="商家详情"
      placement="right"
      width="850"
      :visible="merchanDrawerVisiable"
      @close="handlemerchantInfoDrawerClose"
    >
      <div>
        <merchant-audit ref="merchantDetail" type="detail" @close="handlemerchantInfoDrawerClose" />
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { shopListSearch, merchantAudit } from '@/components/admin/shop';
import { Certification, Margin, Brand, ShopTableList } from '@/const/shop';
import { shopMargin, thousandsFormat } from '@/filters/shop';
import { getMerchantInfo } from '@/service/shop';
import { defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
  name: 'MerchantList',
  components: {
    shopListSearch,
    merchantAudit,
  },
  data() {
    return {
      merchanDrawerVisiable: false,
      ShopTableList,
      thousandsFormat,
      queryData: {},
      pagination: {
        page: 1,
        page_size: 20,
        total: 0,
      },
      Certification,
      Margin,
      Brand,
      listLoading: true,
      list: [],
    };
  },
  setup() {
    const windowHeight = document.documentElement.clientHeight || 1000;
    const tableScrollY = ref(windowHeight - 290);
    const onTopCardRectUpdate = rect => {
      tableScrollY.value = windowHeight - 210 - rect.height;
    };
    return {
      onTopCardRectUpdate,
      tableScrollY,
    };
  },
  computed: {
    tablePage() {
      return {
        current: this.pagination.page,
        total: this.pagination.total,
        pageSize: this.pagination.page_size,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        hideOnSinglePage: false,
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.page}/${Math.ceil(
            total / this.pagination.page_size,
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
      };
    },
  },
  created() {
    /*    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 200;*/
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    // 过滤器
    shopMargin,
    // 搜索
    searchHandler(data) {
      this.queryData = data;
      this.pagination.page = 1;
      this.getTableData();
    },
    paginationHandler(params) {
      this.pagination.page = params.current;
      this.pagination.page_size = params.pageSize;
      this.getTableData();
    },
    async getTableData() {
      this.listLoading = true;
      const _obj = Object.assign({}, this.pagination, this.queryData);
      delete _obj.total;
      const { res } = await getMerchantInfo(_obj);
      this.pagination.total = res.data.total || 0;
      this.list = res.data.merchant_list || [];
      this.listLoading = false;
    },
    showDetail(row) {
      this.merchanDrawerVisiable = true;
      this.$nextTick(() => {
        this.$refs.merchantDetail.show(row);
      });
    },
    handlemerchantInfoDrawerClose() {
      this.merchanDrawerVisiable = false;
    },
  },
});
</script>
